import React, { useState } from "react";
import VisitsDrawer from "../../details/patients/VisitsDrawer";
import CodeListTable from "./CodeListTable";

export default function CodeList({ codes, loading }) {
  const [visitToLoad, setVisitToLoad] = useState(null);

  // here will be the logic to implement the search input
  function closeVisitsDrawer() {
    setVisitToLoad(null);
  }

  function onOpenVisit(visitId) {
    setVisitToLoad(visitId);
  }

  return (
    <>
      <CodeListTable
        codes={codes}
        loading={loading}
        onOpenVisit={onOpenVisit}
      />
      {visitToLoad && (
        <VisitsDrawer toggleDrawer={closeVisitsDrawer} visitId={visitToLoad} />
      )}
    </>
  );
}
