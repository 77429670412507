import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Visits from "./visits/Visits";
import CodeList from "../../shared/codelist/CodeList";
import VisitsDiagnosesProceduresSkeleton from "./skeletons/VisitsDiagnosesProceduresSkeleton";
import { useFetch } from "../../../services/hooks/useFetch";
import { apiPrefix } from "../../../utils/main";
import { CurrentPatient } from "../ProjectDetails";

export default function VisitsDiagnosesProcedures() {
  const {
    patient,
    patientLoading,
    isEncountersUpdated,
    setIsEncountersUpdated,
    isDiagnosisCodesUpdated,
    setIsDiagnosisCodesUpdated,
    isProcedureCodesUpdated,
    setIsProcedureCodesUpdated,
    encounters,
    setEncounters,
    procedureCodes,
    setProcedureCodes,
    diagnosisCodes,
    setDiagnosisCodes,
  } = useContext(CurrentPatient);
  const [tabSelected, setTabSelected] = useState(0);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const { isLoading: encountersLoading, fetch: fetchEncounters } = useFetch();

  const { isLoading: diagnosisCodesLoading, fetch: fetchDiagnosisCodes } =
    useFetch();

  const { isLoading: procedureCodesLoading, fetch: fetchProcedureCodes } =
    useFetch();

  function handleTabChange(_, value) {
    setTabSelected(value);
  }

  const handleVisitListUpdate = useCallback(
    (data) => {
      if (!data) {
        return [];
      }
      let updatedEncounters = [];
      data.forEach((e) =>
        updatedEncounters.push({ ...e, arriveDate: new Date(e.arriveDate) })
      );
      setEncounters(updatedEncounters);
      setIsEncountersUpdated(true);
    },
    [setEncounters, setIsEncountersUpdated]
  );

  useEffect(() => {
    if (!patient.id || !projectId) {
      return;
    }
    if (!isEncountersUpdated) {
      fetchEncounters(
        `${apiPrefix}/projects/${projectId}/patients/${patient.id}/encounters/`,
        (data) => {
          handleVisitListUpdate(data);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    isEncountersUpdated,
    fetchEncounters,
    handleVisitListUpdate,
  ]);

  useEffect(() => {
    if (!patient.id || !projectId) {
      return;
    }
    if (tabSelected === 1 && !isDiagnosisCodesUpdated) {
      fetchDiagnosisCodes(
        `${apiPrefix}/projects/${projectId}/patients/${patient.id}/diagnosisCodes`,
        (data) => {
          setDiagnosisCodes(data);
          setIsDiagnosisCodesUpdated(true);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    tabSelected,
    fetchDiagnosisCodes,
    isDiagnosisCodesUpdated,
    setDiagnosisCodes,
    setIsDiagnosisCodesUpdated,
  ]);

  useEffect(() => {
    if (!patient.id || !projectId) {
      return;
    }
    if (tabSelected === 2 && !isProcedureCodesUpdated) {
      fetchProcedureCodes(
        `${apiPrefix}/projects/${projectId}/patients/${patient.id}/procedureCodes`,
        (data) => {
          setProcedureCodes(data);
          setIsProcedureCodesUpdated(true);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    tabSelected,
    fetchProcedureCodes,
    setProcedureCodes,
    isProcedureCodesUpdated,
    setIsProcedureCodesUpdated,
  ]);

  if (!patient.id || patientLoading || !encounters || !encounters.length) {
    return <VisitsDiagnosesProceduresSkeleton />;
  }

  return (
    <div className="visitsDiagnosesProcedures">
      <Card>
        <CardHeader
          title="Visits, Diagnoses and Procedures"
          titleTypographyProps={{
            align: "left",
            className: "card-title",
            variant: "body1",
          }}
        />
        <CardContent>
          <Tabs
            className={"tabs"}
            value={tabSelected}
            onChange={handleTabChange}
            centered
          >
            <Tab
              className="tab"
              label={
                <TabTitle title={"All Visits"} count={encounters.length} />
              }
            />
            <Tab
              className="tab"
              label={
                <TabTitle
                  title={"All Diagnoses"}
                  count={patient.diagnosisCodeCount}
                />
              }
            />
            <Tab
              className="tab"
              label={
                <TabTitle
                  title={"All Procedures"}
                  count={patient.procedureCodeCount}
                />
              }
            />
          </Tabs>
        </CardContent>
        <CardContent align="left">
          {tabSelected === 0 && <Visits loading={encountersLoading} />}
          {tabSelected === 1 && (
            <CodeList loading={diagnosisCodesLoading} codes={diagnosisCodes} />
          )}
          {tabSelected === 2 && (
            <CodeList loading={procedureCodesLoading} codes={procedureCodes} />
          )}
        </CardContent>
      </Card>
    </div>
  );
}

function TabTitle({ title, count }) {
  return (
    <span className="tab-title">
      {title}{" "}
      {count !== undefined ? (
        <Chip label={count} size="small" />
      ) : (
        <CircularProgress size={20} />
      )}
    </span>
  );
}
