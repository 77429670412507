import React from "react";
import IconContained from "./IconContained";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SensorDoorOutlinedIcon from "@mui/icons-material/SensorDoorOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  ATTRIBUTE_TYPES,
  getCategoryIcon,
} from "../../details/patients/categorize/CategorizeUtils";

export function DeviceDeficiencyIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={getCategoryIcon(ATTRIBUTE_TYPES.DEVICE_DEFICIENCY)}
    />
  );
}
export function ClinicalBenefitIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={getCategoryIcon(ATTRIBUTE_TYPES.CLINICAL_BENEFIT)}
    />
  );
}
export function PerformanceIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={getCategoryIcon(ATTRIBUTE_TYPES.PERFORMANCE)}
    />
  );
}
export function AdverseEventIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={getCategoryIcon(ATTRIBUTE_TYPES.ADVERSE_EVENT)}
    />
  );
}
export function TargetLocationIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={getCategoryIcon(ATTRIBUTE_TYPES.TARGET_LOCATION)}
    />
  );
}
export function IndicationForUseIcon({ type }) {
  return (
    <IconContained
      type={type}
      icon={getCategoryIcon(ATTRIBUTE_TYPES.INDICATION_FOR_USE)}
    />
  );
}
export function CustomCategorizationIcon({ type }) {
  return <IconContained type={type} icon={getCategoryIcon()} />;
}
export function PatientIncludedIcon({ type }) {
  return <IconContained type={type} icon={<ThumbUpAltOutlinedIcon />} />;
}
export function PatientExcludedIcon({ type }) {
  return <IconContained type={type} icon={<BlockOutlinedIcon />} />;
}
export function PatientUndeterminedIcon({ type }) {
  return <IconContained type={type} icon={<PersonOutlineOutlinedIcon />} />;
}
export function AdditionalInformationIcon({ type }) {
  return <IconContained type={type} icon={<InfoOutlinedIcon />} />;
}
export function VisitRelationship({ type }) {
  return <IconContained type={type} icon={<SensorDoorOutlinedIcon />} />;
}
export function ProjectDetailsIcon({ type }) {
  return (
    <IconContained type={type} icon={<SettingsApplicationsOutlinedIcon />} />
  );
}
export function ProjectFiltersIcon({ type }) {
  return <IconContained type={type} icon={<FilterAltOutlinedIcon />} />;
}
