import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Typography,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  Popover,
  ListItemIcon,
  ListItemText,
  Box,
  CircularProgress,
} from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { green, red, blueGrey } from "@mui/material/colors";

import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import { CurrentPatient } from "../ProjectDetails";

export default function MarkModal({
  open,
  onClose,
  anchorEl,
  handlePatientUpdate,
}) {
  const [mark, setMark] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { patient } = useContext(CurrentPatient);
  const projectId = searchParams.get("projectId");
  const {
    update: storePatientAssignment,
    data,
    isLoading: isSaving,
  } = useFetch();

  function handleChange(event) {
    setMark(event.target.value);
  }

  const handleClose = useCallback(() => {
    setMark("");
    setReason("");
    onClose();
  }, [setMark, setReason, onClose]);

  function handleReasonChange(event) {
    setReason(event.target.value);
  }

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const newData = {
      reason: reason,
      isIncluded: mark === "Undetermined" ? null : mark,
    };

    if (newData.reason.trim() !== "") {
      storePatientAssignment(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/reasons`,
        newData,
        "PUT",
        (data) => {
          return data;
        }
      );
    }
  }

  useEffect(() => {
    if (!isSaving && !!data.id) {
      setLoading(false);
      handlePatientUpdate(data);
      handleClose();
    }
  }, [isSaving, data, setLoading, handlePatientUpdate, handleClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div className="categorize-modal">
        <div className="categorize-modal-title">
          <Typography variant="h6">Mark Patient As</Typography>
        </div>
        <div className="categorize-dropdown">
          <FormControl size="small" className="mark-dropdown">
            <InputLabel>Mark Patient As</InputLabel>
            <Select
              label="Mark Patient As"
              value={mark}
              onChange={handleChange}
              variant="standard"
            >
              <MenuItem value={true}>
                <Box className="patient-modal-select-icon">
                  <ListItemIcon>
                    <ThumbUpOutlinedIcon
                      fontSize="small"
                      sx={{ color: green[600] }}
                    />
                  </ListItemIcon>
                  <ListItemText>Included</ListItemText>
                </Box>
              </MenuItem>
              <MenuItem value={false}>
                <Box className="patient-modal-select-icon">
                  <ListItemIcon>
                    <BlockOutlinedIcon
                      fontSize="small"
                      sx={{ color: red[600] }}
                    />
                  </ListItemIcon>
                  <ListItemText>Excluded</ListItemText>
                </Box>
              </MenuItem>
              <MenuItem value={"Undetermined"}>
                <Box className="patient-modal-select-icon">
                  <ListItemIcon>
                    <PersonOutlinedIcon
                      fontSize="small"
                      sx={{ color: blueGrey[600] }}
                    />
                  </ListItemIcon>
                  <ListItemText>Undetermined</ListItemText>
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="categorize-modal-textfield">
          <TextField
            label="Enter Note/Reason"
            fullWidth
            className="categorize-modal-textfield"
            multiline
            rows={8}
            value={reason}
            onChange={handleReasonChange}
          />
        </div>
        <div className="categorize-modal-footer-mark">
          <Button onClick={handleClose} variant={"outlined"} size={"small"}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            size={"small"}
            endIcon={loading && <CircularProgress size="1.5rem" />}
            disabled={reason.trim() === "" || loading}
          >
            Submit
          </Button>
        </div>
      </div>
    </Popover>
  );
}
