import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
import {
  BrokenImageOutlined,
  InfoOutlined,
  NoteAltOutlined,
  ReportProblemOutlined,
  RoomOutlined,
} from "@mui/icons-material";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import React from "react";

export const ATTRIBUTE_TYPES = {
  DEVICE_DEFICIENCY: "6a313d36-9c45-4813-a001-fb0f14f62429",
  CLINICAL_BENEFIT: "7af609de-01cf-49c4-8d3f-52951266804e",
  PERFORMANCE: "8d83844c-87d3-429f-a286-5b60525985a0",
  ADVERSE_EVENT: "3dc7dff2-2f82-45f7-85fc-782b4b7f78db",
  TARGET_LOCATION: "a811475b-d622-41af-9df1-221816fe0411",
  INDICATION_FOR_USE: "da015180-6899-423a-9bcd-72a21cc475da",
};

export function getCategoryIcon(attributeTypeId) {
  if (attributeTypeId === ATTRIBUTE_TYPES.CLINICAL_BENEFIT)
    return <MedicalServicesOutlinedIcon />;
  if (attributeTypeId === ATTRIBUTE_TYPES.DEVICE_DEFICIENCY)
    return <BrokenImageOutlined />;
  if (attributeTypeId === ATTRIBUTE_TYPES.ADVERSE_EVENT)
    return <ReportProblemOutlined />;
  if (attributeTypeId === ATTRIBUTE_TYPES.PERFORMANCE)
    return <TrendingUpOutlinedIcon />;
  if (attributeTypeId === ATTRIBUTE_TYPES.TARGET_LOCATION)
    return <RoomOutlined />;
  if (attributeTypeId === ATTRIBUTE_TYPES.INDICATION_FOR_USE)
    return <NoteAltOutlined />;
  return <InfoOutlined />;
}

export function getAnnexName(annex) {
  if (annex === "A") return "Annex A: Medical Device Problem";
  if (annex === "E")
    return "Annex E: Health Effects - Clinical Signs and Symptoms or Conditions";
  if (annex === "F") return "Annex F: Health Effects - Health Impact";
}

export function getCategoryByType(attributeTypes, attributeTypeId) {
  return attributeTypes.find((a) => a.id === attributeTypeId);
}

export function getCategoryName(attributeTypes, attribute) {
  let category = getCategoryByType(attributeTypes, attribute.attributeTypeId);
  return category?.name ?? "";
}

export function getAttributeValue(imdrfEnabled, attributeTypes, attribute) {
  let category = getCategoryByType(attributeTypes, attribute.attributeTypeId);
  if (imdrfEnabled) {
    if (attribute.imdrfCode) {
      if (attribute.imdrfCode) {
        return attribute.imdrfCode.term + " - " + attribute.imdrfCode.code;
      }
    }
  }
  if (category.id === ATTRIBUTE_TYPES.DEVICE_DEFICIENCY) {
    return yesNoDeficient(attribute.attributeValue);
  }
  if (
    category.id === ATTRIBUTE_TYPES.PERFORMANCE ||
    category.id === ATTRIBUTE_TYPES.CLINICAL_BENEFIT
  ) {
    return successFail(attribute.attributeValue);
  }
  return attribute.attributeValue;
}

export function getOtherData(imdrfEnabled, attributeTypes, attribute) {
  let otherData = [];
  if (!imdrfEnabled) return otherData;

  let attributeTypeId = attribute.attributeTypeId;
  let category = getCategoryByType(attributeTypes, attributeTypeId);

  if (
    category.attributeTypeFields &&
    category.attributeTypeFields.length > 0 &&
    attribute.fieldData &&
    attribute.fieldData.length > 0
  ) {
    let fieldDataList = attribute.fieldData;
    category.attributeTypeFields.forEach((atf) => {
      let fieldDataRow = fieldDataList.find(
        (fd) => fd.attributeTypeFieldId === atf.id
      );
      if (fieldDataRow) {
        let displayValue;
        if (atf.valueType === "BOOLEAN") {
          displayValue = yesNo(fieldDataRow.fieldValue);
        } else {
          displayValue = fieldDataRow.fieldValue;
        }
        let fieldData = atf.label + ": " + displayValue;
        otherData.push(fieldData);
      }
    });
  } else {
    if (
      category.id === ATTRIBUTE_TYPES.DEVICE_DEFICIENCY &&
      attribute.attributeValue === "true"
    ) {
      otherData.push(yesNoDeficient(attribute.attributeValue));
    } else if (
      category.id === ATTRIBUTE_TYPES.PERFORMANCE &&
      attribute.attributeValue === "false"
    ) {
      otherData.push("Sub-Category: " + successFail(attribute.attributeValue));
    }
  }
  return otherData;
}

export function successFail(status) {
  let isTrueSet = String(status).toLowerCase() === "true";
  if (isTrueSet) return "Success";
  return "Failure";
}

export function yesNo(status) {
  let isTrueSet = String(status).toLowerCase() === "true";
  if (isTrueSet) return "Yes";
  return "No";
}

export function yesNoDeficient(status) {
  let isTrueSet = String(status).toLowerCase() === "true";
  if (isTrueSet) return "Yes (device deficient)";
  return "No (device not deficient)";
}
