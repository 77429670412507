import React from "react";
import { Link } from "react-router-dom";
import theme from "../../styles/theme";
import {
  Chip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TableCell,
  Link as MuiLink,
} from "@mui/material";

export default function TableComponent({ projects }) {
  return (
    <div className={"table-box"}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow key="1">
              <TableCell>Project Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Device Name</TableCell>
              <TableCell>Patient Records</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((project) => {
              const descriptions = project.devices?.map(
                (device) => device.description
              );
              return (
                <TableRow key={project.id}>
                  <TableCell>
                    <MuiLink
                      component={Link}
                      to={`/details?projectId=${project.id}`}
                      variant="body2"
                    >
                      {project.name}
                    </MuiLink>
                  </TableCell>
                  <TableCell>
                    <Chip label={project.statusName} size="small" />
                  </TableCell>
                  <TableCell>{descriptions?.join(", ")}</TableCell>
                  <TableCell sx={{ color: theme.palette.link.main }}>
                    {project.patientCount != null && project.patientCount >= 10
                      ? project.patientCount
                      : "<10"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
