import React from "react";
import { Badge, Box } from "@mui/material";
import Badgeicon from "./BadgeIcon";

export default function IconContained({ type, icon }) {
  return (
    <Badge
      badgeContent={<Badgeicon type={type} />}
      classes={{ badge: "icon-badge" }}
      sx={{ "& .MuiBadge-badge": { fontSize: 16 } }}
    >
      <Box className={"icon-container"}>{icon}</Box>
    </Badge>
  );
}
