import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InfoIcon from "@mui/icons-material/Info";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import theme from "../../../styles/theme";
import EmptyCard from "./EmptyCard";
import SkeletonCard from "./SkeletonCard";
import RelatedVisitModal from "./RelatedVisitModal";
import CodesChips from "./CodesChips";
import NoteText from "./NoteText";
import { useFetch } from "../../../services/hooks/useFetch";
import { projectsPrefix } from "../../../services/ProjectsServices";
import IMDRFCategorize from "./categorize/IMDRFCategorize";
import { getDiffDaysMessage } from "../../../utils/main";
import { CurrentPatient } from "../ProjectDetails";

export default function VisitsDrawer({ toggleDrawer, visitId }) {
  const [encounterData, setEncounterData] = useState(null);
  const [notesData, setNotesData] = useState(null);
  const [sortColumn, setSortColumn] = useState("+diffdays");
  const [relatedVisitModal, setRelatedVisitModal] = useState(false);
  const [categorizeModal, setCategorizeModal] = useState(false);
  const [mark, setMark] = useState("");
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { patient, encounters, updateEncounters } = useContext(CurrentPatient);

  const {
    fetch: fetchEncounter,
    isLoading: isEncounterLoading,
    error: fetchEncounterError,
  } = useFetch();
  const {
    fetch: fetchNotes,
    isLoading: isNotesLoading,
    error: fetchNotesError,
  } = useFetch();

  useEffect(() => {
    if (fetchEncounterError) {
      console.log("Error: " + fetchEncounterError);
    }
    if (fetchNotesError) {
      console.log("Error: " + fetchNotesError);
    }
  }, [fetchEncounterError, fetchNotesError]);

  function handleNotesSort(e) {
    //TODO: Keeping this client side for now. When paging is implemented, we will move this server side
    let newSortCol = null;
    if (e) {
      newSortCol = e.currentTarget?.attributes["sortcol"]?.value;
      if (newSortCol === sortColumn) return;
      setSortColumn(newSortCol);
    }

    if (newSortCol === "-priority") {
      notesData.sort((a, b) => b.priority - a.priority);
    } else if (newSortCol === "+priority") {
      notesData.sort((a, b) => a.priority - b.priority);
    } else if (newSortCol === "-title") {
      notesData.sort((a, b) => b.title.localeCompare(a.title));
    } else if (newSortCol === "+title") {
      notesData.sort((a, b) => a.title.localeCompare(b.title));
    } else if (newSortCol === "-date") {
      notesData.sort((a, b) => b.diffDays - a.diffDays);
    } else if (newSortCol === "+date") {
      notesData.sort((a, b) => a.diffDays - b.diffDays);
    }
  }

  function closeVisitModal() {
    setRelatedVisitModal(false);
    setMark(null);
  }

  function closeCategorizeModal() {
    setCategorizeModal(false);
    setMark(null);
  }

  function handleVisitUpdate(data) {
    setEncounterData(data);
    updateEncounters(data);
  }

  function handleVisitModal(e) {
    setMark(e.currentTarget);
    setRelatedVisitModal(true);
  }

  function handleCategorizeModal(e) {
    setMark(e.currentTarget);
    setCategorizeModal(true);
  }

  useEffect(() => {
    if (!isEncounterLoading && !fetchEncounterError && !encounterData) {
      let visit = encounters.find((e) => e.id === visitId);
      setEncounterData(visit);

      fetchEncounter(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/encounters/${visitId}`,
        (data) => {
          if (data.arriveDate) {
            data.arriveDate = new Date(data.arriveDate);
          }
          setEncounterData(data);
        }
      );
    }
  }, [
    patient.id,
    projectId,
    encounters,
    fetchEncounter,
    fetchEncounterError,
    visitId,
    encounterData,
    isEncounterLoading,
  ]);

  useEffect(() => {
    if (
      !fetchNotesError &&
      !isNotesLoading &&
      encounterData &&
      encounterData.notesCount > 0 &&
      (!notesData || notesData.length < encounterData.notesCount)
    ) {
      let offset = 0;
      let limit = window.env.notesPageSize ?? 50;

      if (notesData && notesData.length > 0) {
        offset = notesData.length;
      }

      fetchNotes(
        `${projectsPrefix}/${projectId}/patients/${patient.id}/encounters/${visitId}/notes?offset=${offset}&limit=${limit}`,
        (data) => {
          if (data == null || data.length === 0) {
            encounterData.notesCount = 0;
            //stop the fetching process
          } else {
            let updatedNotes = [...(notesData ?? []), ...data];
            if (updatedNotes.length === encounterData.notesCount) {
              //default to diffDays sort
              updatedNotes.sort((a, b) => a.diffDays - b.diffDays);
            }
            setNotesData(updatedNotes);
          }
        }
      );
    }
  }, [
    projectId,
    patient.id,
    visitId,
    notesData,
    isNotesLoading,
    fetchNotes,
    fetchNotesError,
    encounterData,
  ]);

  return (
    <Drawer
      anchor="right"
      open={true}
      onClose={toggleDrawer}
      PaperProps={{
        sx: { width: "min(100%, 1052px)" },
      }}
    >
      <div className="drawer-internal-wrapper">
        <div className="visit-modal-header">
          <div className="card-header">
            <div className="modal-header-left">
              <IconButton
                onClick={toggleDrawer}
                sx={{ "&:hover": { bgcolor: "action.hover" } }}
              >
                <ChevronRightIcon />
              </IconButton>
              {encounterData &&
                !encounterData.indexProcedure &&
                encounterData.diffDays !== undefined && (
                  <Typography variant="subtitle1">
                    {getDiffDaysMessage(encounterData.diffDays)}
                  </Typography>
                )}
              {encounterData &&
                encounterData.indexProcedure &&
                encounterData.arriveDate && (
                  <Typography variant="subtitle1">
                    Index Procedure: {encounterData.arriveDate.getUTCFullYear()}
                  </Typography>
                )}
              {encounterData && encounterData.isRelated === false && (
                <Chip
                  label="Unrelated"
                  size="small"
                  sx={{ borderRadius: 3 }}
                  onDelete={() => {}}
                  deleteIcon={
                    <Tooltip title={encounterData.reason}>
                      <StickyNote2OutlinedIcon
                        fontSize="small"
                        style={{ color: theme.palette.action.active }}
                      />
                    </Tooltip>
                  }
                />
              )}
            </div>
            <div className="modal-header-right">
              <Button
                size={"small"}
                variant={"outlined"}
                className={"categorize-button"}
                onClick={handleCategorizeModal}
              >
                Categorize Patient
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                id="Mark Visit Button"
                onClick={handleVisitModal}
              >
                {encounterData &&
                (encounterData.isRelated || encounterData.isRelated === null)
                  ? "Mark Visit As Unrelated"
                  : "Mark Visit As Related"}
              </Button>
            </div>
            {categorizeModal && (
              <IMDRFCategorize
                onClose={closeCategorizeModal}
                open={true}
                anchorEl={mark}
              />
            )}
          </div>
        </div>
        <Divider flexItem />
        <div className="modal-content-visits">
          <div className="modal-content-visits-left">
            {encounterData && encounterData.encounterNumber && (
              <div className={"encounter-type"}>
                <Typography className={"label"}>Visit ID:</Typography>
                <Typography className={"type"} variant={"body2"}>
                  {encounterData.encounterNumber}
                </Typography>
              </div>
            )}
            {encounterData && encounterData.encounterType && (
              <div className={"encounter-type"}>
                <Typography className={"label"}>Visit Type:</Typography>
                <Typography className={"type"} variant={"body2"}>
                  {encounterData.encounterType}
                </Typography>
              </div>
            )}
            <Card className="clinical-notes-insight">
              <CardContent className="clinical-notes-insight-content">
                <div className="clinical-notes-insight-content-title">
                  <Typography variant="body2">
                    Clinical Notes Insights
                  </Typography>
                  <Tooltip
                    title="3Aware extracts information from the Clinical Notes
                      associated to the Visit to highlight references made
                      to Medications, Allergies, and Other Devices Used
                      within the unstructured data."
                    placement="top-end"
                  >
                    <InfoIcon
                      size="small"
                      sx={{ color: theme.palette.info.main }}
                    />
                  </Tooltip>
                </div>
                <div className="clinical-notes-insight-items">
                  <div className={"clinical-notes-insight-item"}>
                    <Typography className={"visit-card-label"} variant="body2">
                      Medications
                    </Typography>
                    <Typography variant="body2">
                      {(
                        (encounterData && encounterData.medications) ||
                        []
                      ).join(", ")}
                    </Typography>
                  </div>
                  <div className={"clinical-notes-insight-item"}>
                    <Typography className={"visit-card-label"} variant="body2">
                      Allergies
                    </Typography>
                    <Typography variant="body2">
                      {((encounterData && encounterData.allergies) || []).join(
                        ", "
                      )}
                    </Typography>
                  </div>
                  <div className={"clinical-notes-insight-item"}>
                    <Typography className={"visit-card-label"} variant="body2">
                      Other Devices Used
                    </Typography>
                    <Typography variant="body2">
                      {(
                        (encounterData && encounterData.otherDevices) ||
                        []
                      ).join(", ")}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card className="visit-drawer-card">
              <CardContent className="diagnosis-codes">
                <Typography variant="subtitle1">Diagnoses</Typography>
                {(!encounterData ||
                  !encounterData.diagnosisCodes ||
                  encounterData.arriveDate === "") && (
                  <SkeletonCard name="empty-data" />
                )}
                {encounterData &&
                  encounterData.diagnosisCodes &&
                  encounterData.diagnosisCodes.length > 0 && (
                    <CodesChips
                      codes={encounterData.diagnosisCodes}
                      chipSize={"small"}
                    />
                  )}
                {encounterData &&
                  encounterData.diagnosisCodes &&
                  encounterData.diagnosisCodes.length === 0 &&
                  encounterData.arriveDate !== "" && (
                    <EmptyCard type="Diagnoses" />
                  )}
              </CardContent>
            </Card>
            <Card className="visit-drawer-card">
              <CardContent className="procedure-codes">
                <Typography variant="subtitle1"> Procedures </Typography>
                {(!encounterData ||
                  !encounterData.procedureCodes ||
                  encounterData.arriveDate === "") && (
                  <SkeletonCard name="empty-data" />
                )}
                {encounterData &&
                  encounterData.procedureCodes &&
                  encounterData.procedureCodes.length > 0 && (
                    <CodesChips
                      codes={encounterData.procedureCodes}
                      chipSize={"small"}
                    />
                  )}
                {encounterData &&
                  encounterData.procedureCodes &&
                  encounterData.procedureCodes.length === 0 &&
                  encounterData.arriveDate !== "" && (
                    <EmptyCard type="Procedures" />
                  )}
              </CardContent>
            </Card>
          </div>
          <div className="modal-content-visits-right">
            <div className="modal-content-right-header">
              <div className="modal-content-clinical-notes">
                <StickyNote2OutlinedIcon />
                <Typography variant="body1">Clinical Notes</Typography>
              </div>
            </div>

            <div className="visits-header">
              <div className="visits-row">
                <Typography>Title</Typography>
                {notesData && sortColumn !== "-title" && (
                  <ArrowDownwardIcon
                    size="small"
                    onClick={handleNotesSort}
                    sortcol="-title"
                    sx={{ color: theme.palette.action.active }}
                  />
                )}
                {notesData && sortColumn !== "+title" && (
                  <ArrowUpwardIcon
                    size="small"
                    onClick={handleNotesSort}
                    sortcol="+title"
                    sx={{ color: theme.palette.action.active }}
                  />
                )}
              </div>
              <div className="visits-row">
                <Typography>Date</Typography>
                {notesData && sortColumn !== "-date" && (
                  <ArrowDownwardIcon
                    size="small"
                    onClick={handleNotesSort}
                    sortcol="-date"
                    sx={{ color: theme.palette.action.active }}
                  />
                )}
                {notesData && sortColumn !== "+date" && (
                  <ArrowUpwardIcon
                    size="small"
                    onClick={handleNotesSort}
                    sortcol="+date"
                    sx={{ color: theme.palette.action.active }}
                  />
                )}
              </div>
              <div className="visits-row">
                <Typography>Priority</Typography>
                {notesData && sortColumn !== "-priority" && (
                  <ArrowDownwardIcon
                    size="small"
                    onClick={handleNotesSort}
                    sortcol="-priority"
                    sx={{ color: theme.palette.action.active }}
                  />
                )}
                {notesData && sortColumn !== "+priority" && (
                  <ArrowUpwardIcon
                    size="small"
                    onClick={handleNotesSort}
                    sortcol="+priority"
                    sx={{ color: theme.palette.action.active }}
                  />
                )}
                <Tooltip
                  title="Clinical Notes with a priority icon may include evidence of an adverse event, clinical or technical failure as identified by the 3Aware system."
                  placement="top-end"
                >
                  <InfoIcon
                    size="small"
                    sx={{ color: theme.palette.info.main }}
                  />
                </Tooltip>
              </div>
            </div>
            <Divider flexItem variant="fullWidth" />
            {!encounterData && <SkeletonCard name="visit-drawer-card" />}

            {encounterData && encounterData.notesCount === 0 && (
              <Card className="visit-drawer-card">
                <CardContent className={"clinical-notes"}>
                  <EmptyCard type="Clinical Notes" />
                </CardContent>
              </Card>
            )}

            {encounterData &&
              encounterData.notesCount > 0 &&
              notesData &&
              notesData.map((note, index) => (
                <Card
                  key={note.title + "_" + index}
                  className="clinical-notes-note"
                >
                  <div>
                    <div className="modal-content-title-box">
                      <div className="column-1">
                        <Typography
                          variant="h6"
                          className="modal-content-boldtext"
                        >
                          {note.title}
                        </Typography>
                        {note.priority > 0 && (
                          <OfflineBoltOutlinedIcon
                            size="small"
                            sx={{ color: theme.palette.info.main }}
                          />
                        )}
                      </div>
                      <div className="column-2">
                        <Typography variant="body1">
                          {getDiffDaysMessage(note.diffDays)}
                        </Typography>
                      </div>
                    </div>
                    <Divider />
                    <div className="modal-content-subtitle-box">
                      <NoteText note={note} />
                    </div>
                    <Divider />
                    <div className="modal-content-button">
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        className={"categorize-button"}
                        onClick={handleCategorizeModal}
                      >
                        Categorize Patient
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </div>
        {relatedVisitModal && (
          <RelatedVisitModal
            anchorEl={mark}
            onClose={closeVisitModal}
            visit={encounterData}
            handleVisitUpdate={handleVisitUpdate}
          />
        )}
      </div>
    </Drawer>
  );
}
