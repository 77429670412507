import React from "react";
import {
  AdditionalInformationIcon,
  AdverseEventIcon,
  ClinicalBenefitIcon,
  PatientExcludedIcon,
  PatientIncludedIcon,
  PatientUndeterminedIcon,
  PerformanceIcon,
  ProjectDetailsIcon,
  ProjectFiltersIcon,
  VisitRelationship,
  TargetLocationIcon,
  IndicationForUseIcon,
  DeviceDeficiencyIcon,
  CustomCategorizationIcon,
} from "../../customicons/CustomIcons";

const projectFiltersIcon = [
  "Demographic Filters",
  "Diagnosis Code Filters",
  "Procedure Code Filters",
];
export default function PatientsLogIcons({ log }) {
  if (!log.type) {
    return <DefaultComponent />;
  }
  if (log.type.category === "Patient Categorization") {
    if (!log.subtype) {
      return <DefaultComponent />;
    }

    let logSubType = log.subtype.description.toLowerCase();
    if (logSubType === "clinical benefit") {
      return <ClinicalBenefitIcon type={log.type.action} />;
    }
    if (logSubType === "performance") {
      return <PerformanceIcon type={log.type.action} />;
    }
    if (logSubType === "adverse event" || logSubType === "complication") {
      return <AdverseEventIcon type={log.type.action} />;
    }
    if (logSubType === "device deficient") {
      return <DeviceDeficiencyIcon type={log.type.action} />;
    }
    if (logSubType === "target location") {
      return <TargetLocationIcon type={log.type.action} />;
    }
    if (logSubType === "indication for use") {
      return <IndicationForUseIcon type={log.type.action} />;
    }
    return <CustomCategorizationIcon />;
  }
  if (log.type.category === "Patient Include/Exclude") {
    if (log.value === "Included") {
      return <PatientIncludedIcon />;
    }
    if (log.value === "Excluded") {
      return <PatientExcludedIcon />;
    }
    if (log.value === "Undetermined") {
      return <PatientUndeterminedIcon />;
    }
  }
  if (log.type.category === "Additional Information") {
    return <AdditionalInformationIcon type={log.type.action} />;
  }
  if (log.type.category === "Visit Related/Not Related") {
    return <VisitRelationship type={log.type.action} />;
  }
  if (projectFiltersIcon.includes(log.type.category)) {
    return <ProjectFiltersIcon type={log.type.action} />;
  }
  if (log.type.category === "Project Details") {
    return <ProjectDetailsIcon type={log.type.action} />;
  }
  return <DefaultComponent />;
}
function DefaultComponent() {
  return <div></div>;
}
