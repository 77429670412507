import React, { useContext } from "react";
import { Box } from "@mui/material";
import VisitsInfo from "./VisitsInfo";
import VisitsSkeleton from "./skeletons/VisitsSkeleton";
import { CurrentPatient } from "../../ProjectDetails";

export default function Visits({ loading }) {
  const { encounters } = useContext(CurrentPatient);
  if (!encounters || encounters.length === 0 || loading) {
    return <VisitsSkeleton />;
  }
  return (
    <div className="visits-card">
      <Box
        sx={{
          display: "flex",
          width: 1,
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        <VisitsInfo />
      </Box>
    </div>
  );
}
