import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import ActivityCard from "./activitycard/ActivityCard";
import Activity from "./Activity";
import { apiPrefix } from "../../../utils/main";
import { patientActivityLogs } from "../../../models/patient";
import { useFetch } from "../../../services/hooks/useFetch";
import { CurrentPatient } from "../../details/ProjectDetails";

export default function PatientActivity({ onClose, onLoadVisit }) {
  const { patient } = useContext(CurrentPatient);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const { fetch, data: logs } = useFetch();

  const hyperlink = {
    action: onLoadVisit,
  };

  useEffect(() => {
    fetch(
      `${apiPrefix}/activity/projects/${projectId}/patients/${patient.id}`,
      (data) => {
        return patientActivityLogs(data);
      }
    );
  }, [projectId, patient.id, fetch]);

  return (
    <Activity title={"Patient Activity"} onClose={onClose} logs={logs}>
      {(log) => (
        <ActivityCard
          key={log.id}
          cardType={"patient"}
          log={log}
          hyperlink={hyperlink}
        />
      )}
    </Activity>
  );
}
