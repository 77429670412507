import { Grid, Stack } from "@mui/material";
import PatientCard from "./PatientCard";
import IndexProcedureVisitCard from "./IndexProcedureVisitCard";
import VisitsDiagnosesProcedures from "./VisitsDiagnosesProcedures";

export default function SelectedPatientMainBody() {
  return (
    <Grid className={"data"} container spacing={2}>
      <Grid item xs={6}>
        <Stack spacing={2}>
          <PatientCard />
          <IndexProcedureVisitCard />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <VisitsDiagnosesProcedures />
      </Grid>
    </Grid>
  );
}
